<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 resources-screen gray-bg"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="resource-header icon-hea1">
        <template v-slot:left>
          <home-button />
        </template>
        <div class="component-title">
          {{ displayLabelName("services.service-items.service-items") }}
        </div>
        <template v-slot:right>
          <router-link
            v-if="can('MANAGE_SERVICE_ITEMS', 'create')"
            :to="{
              name: 'r_add-service-item'
            }"
          >
            <icon icon="#cx-hea1-add" />
          </router-link>
        </template>
      </top-header-menu-wrapper>
      <section class="search-wrapper time-zone-search">
        <div class="search-field">
          <input
            type="text"
            @keyup="searchQuery = $event.target.value"
            :placeholder="displayLabelName('global.search.search')"
          />
          <div class="icon search">
            <icon icon="#cx-app1-search-16x16" width="16" height="16" />
          </div>
        </div>
      </section>
      <div
        v-if="paginationData && paginationCountActive"
        class="clebex-pagination-count-wrapper"
      >
        <span class="clebex-pagination-count"
          >{{ topScreen }} - {{ bottomScreen }} /
          {{ paginationData.total }}</span
        >
      </div>
    </template>
    <div class="scroll"></div>
    <ul
      class="clebex-item-section pill"
      v-if="listOfServiceItems && listOfServiceItems.length"
    >
      <li
        class="clebex-item-section-item"
        v-for="serviceItem in listOfServiceItems"
        :key="serviceItem.id"
      >
        <button
          class="clebex-item-content-wrapper"
          @click="editServiceItem(serviceItem)"
          v-if="!editing"
        >
          <span class="label">
            <span class="highlight">{{ serviceItem.name }}</span>
          </span>
          <span class="follow-up-icons">
            <span class="follow-up-icon-item">
              <icon icon="#cx-app1-information"></icon>
            </span>
          </span>
        </button>
        <div class="clebex-item-content-wrapper" v-else>
          <div class="checkbox tiny alt block">
            <input
              type="checkbox"
              :id="`service-item${serviceItem.id}`"
              name="user"
              :selected="selectedServiceItems.includes[serviceItem.id]"
              :value="serviceItem.id"
              @change="selectServiceItem(serviceItem)"
            />
            <label :for="`service-item${serviceItem.id}`">
              <svg-icon icon="checkmark-strait"></svg-icon>
              <span class="highlight">
                {{ serviceItem.name }}
              </span>
            </label>
          </div>
        </div>
      </li>
    </ul>
    <template v-slot:footer>
      <nav class="actions-menu theme-gray">
        <ul class="actions-list">
          <li class="action">
            <button
              class="action-btn"
              :class="{ active: editing }"
              @click="setEditing"
            >
              {{ displayLabelName("global.footer.select") }}
            </button>
          </li>
          <li class="action" v-if="selectedServiceItems.length && editing">
            <button
              class="action-btn"
              :disabled="processing"
              @click="showDeleteModal = true"
              v-if="can('MANAGE_SERVICE_ITEMS', 'delete')"
            >
              <icon icon="#cx-app1-delete-02-14x14" />
            </button>
            <span class="action-btn deleted-count">{{
              "(" +
                selectedServiceItems.length +
                "/" +
                listOfServiceItems.length +
                ")"
            }}</span>
          </li>
        </ul>
      </nav>
    </template>
    <screen-modal
      class="confirm-modal"
      type="success"
      :confirm-action="checkDelete"
      :confirm-button-label="displayLabelName('global.buttons.ok')"
      :cancelButtonLabel="displayLabelName('global.buttons.cancel')"
      :show="showDeleteModal"
      @close="showDeleteModal = false"
    >
      <h3 class="modal-title">
        {{ displayLabelName("services.service-items.delete-title") }}
      </h3>
      <p class="text">
        {{ displayLabelName("services.service-items.delete-action-text") }}
        <br />
        {{ displayLabelName("global.messages.delete-action-question") }}
      </p>
    </screen-modal>
    <screen-modal
      v-if="showDeleteConfirmModal"
      :hide-timer="true"
      class="confirm-modal delete-modal"
      type="warning"
      :confirm-action="startDelete"
      :confirm-button-label="displayLabelName('global.buttons.ok')"
      :cancel-button-label="displayLabelName('global.buttons.cancel')"
      :show="showDeleteConfirmModal"
      @close="closeDeleteModals"
    >
      <h3 class="modal-title">
        {{ displayLabelName("services.service-items.delete-warning-title") }}
      </h3>
      <p class="text">
        {{ displayLabelName("services.service-items.delete-warning-message") }}
        <br />
        {{ displayLabelName("global.messages.delete-warning-question") }}
      </p>
    </screen-modal>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState } from "vuex";
import paginationMixin from "@/services/mixins/pagination/pagination-mixin";

export default {
  name: "ResourceTypes",
  mixins: [paginationMixin],
  data() {
    return {
      processing: false,
      searchQuery: "",
      editing: false,
      selectedServiceItems: [],
      showDeleteModal: false,
      showDeleteConfirmModal: false
    };
  },
  computed: {
    ...mapState("service", ["serviceItems", "selectedServiceItem"]),
    listOfServiceItems() {
      const query = this.searchQuery;
      if (this.serviceItems && this.serviceItems.length) {
        if (query && query.length > 1) {
          return this.serviceItems.filter(item =>
            item.name.toLowerCase().includes(query.toLowerCase())
          );
        } else {
          return this.serviceItems;
        }
      }
      return null;
    }
  },
  created() {
    this.getServiceItems();
  },
  methods: {
    ...mapActions("service", [
      "getServiceItems",
      "deleteServiceItem",
      "checkDeleteServiceItem"
    ]),
    editServiceItem(serviceItem) {
      if (this.can("MANAGE_SERVICE_ITEMS", "read")) {
        setTimeout(() => {
          this.$router.push({
            name: "r_edit-service-item",
            params: { service_item_id: serviceItem.id }
          });
        }, 0);
      }
    },
    selectServiceItem(serviceItem) {
      if (this.selectedServiceItems.includes(serviceItem.id)) {
        this.selectedServiceItems.splice(
          this.selectedServiceItems.indexOf(serviceItem.id),
          1
        );
      } else {
        this.selectedServiceItems.push(serviceItem.id);
      }
    },
    setEditing() {
      this.editing = !this.editing;
      this.selectedServiceItems = [];
      this.$router.push({ name: "r_service-items" });
    },
    async checkDelete() {
      let checkDelete = true;
      for (var i = 0; i < this.selectedServiceItems.length; i++) {
        var checkDel = await this.checkDeleteServiceItem(
          this.selectedServiceItems[i]
        );
        if (checkDel && checkDel.data && checkDel.data.exists) {
          checkDelete = false;
        }
      }

      if (checkDelete == true) {
        for (var j = 0; j < this.selectedServiceItems.length; j++) {
          await this.deleteServiceItem(this.selectedServiceItems[j]);
        }
        this.selectedServiceItems = [];
      } else {
        this.showDeleteConfirmModal = true;
      }
    },
    async startDelete() {
      this.processing = true;
      for (var i = 0; i < this.selectedServiceItems.length; i++) {
        await this.deleteServiceItem(this.selectedServiceItems[i]);
      }
      this.processing = false;
      this.editing = false;
      this.selectedServiceItems = [];
      this.$nextTick(() => {
        this.$router.push({ name: "r_service-items" });
      });
    },
    closeDeleteModals() {
      this.showDeleteConfirmModal = false;
      this.showDeleteModal = false;
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  }
};
</script>
<style>
.deleted-count {
  position: relative;
  top: -4px;
  margin: 5px 8px;
  padding-left: 0px !important;
  margin-left: 0px;
}
</style>
